import React from 'react'
import {motion, useTransform, useScroll} from 'framer-motion'

import {useRefScrollProgress} from './hooks/useRefScrollProgress'
import {isTouchDevice} from './utils/is-touch-device'
import Footer from './components/Footer'

import logo from './assets/images/logo.svg'
import rollingWave from './assets/images/rolling-wave.jpg'
import cardVideo01 from './assets/videos/01.mov'
import cardVideo02 from './assets/videos/02.mov'
import cardVideo03 from './assets/videos/03.mov'

const App = () => {
  const refSectionHero = React.useRef(null)
  const refSectionPostcards = React.useRef(null)
  const {scrollYProgress} = useScroll()
  const {start, end} = useRefScrollProgress(refSectionHero)
  const {start: startCards, end: endCards} =
    useRefScrollProgress(refSectionPostcards)
  const deltaHero = end - start
  const deltaCards = endCards - startCards

  const transitionWavesScreen = useTransform(
    scrollYProgress,
    [start, end - deltaHero / 5],
    [0.83, 0],
  )
  const scrollCard02 = useTransform(
    scrollYProgress,
    [startCards, startCards + deltaCards / 2],
    ['200%', '0%'],
  )
  const scrollCard01 = useTransform(
    scrollYProgress,
    [endCards - deltaCards / 2, endCards - deltaCards / 5],
    ['200%', '0%'],
  )

  return (
    <div>
      <section ref={refSectionHero}>
        <div className="bg-waves sticky top-0 flex h-screen flex-col items-center bg-cover">
          <div className="relative z-50 mx-auto w-[112px] pt-9 md:mx-0 md:w-[140px] lg:left-8 lg:top-20 lg:w-[160px] lg:self-start lg:pt-0 xl:w-auto">
            <img src={logo} alt="Save Our Surf Brains" className="blok" />
          </div>
          <motion.div
            className="absolute inset-0 flex items-center justify-center bg-white font-black text-[24px] uppercase mix-blend-screen sm:text-[44px] md:text-[56px] lg:text-[72px] xl:text-[94px] 2xl:text-[116px]"
            style={{opacity: transitionWavesScreen}}
          >
            <span>#saveoursurfbrains</span>
          </motion.div>
          <div className="absolute bottom-0 flex flex-col items-center font-headings text-[1rem] text-cello md:text-[25px]">
            <div>scroll down</div>
            <div className="mt-2 h-7 w-0.5 bg-cello md:mt-4 md:h-12 md:w-1" />
          </div>
        </div>
        <div className="h-[130vh]" />
      </section>
      <div className="pt-10 pb-6 md:pt-14 md:pb-10 lg:pt-20 lg:pb-16 xl:pt-32 xl:pb-20">
        <div className="container mx-auto max-w-5xl px-5 text-center text-[1rem] leading-normal md:text-[1.5rem] lg:text-[2rem] lg:leading-tight xl:text-[3rem]">
          <h3>
            We did this for a simple reason. To give you something different.
            Something that might make you laugh or even scratch your head. But
            definitely something you wouldn’t mind looking at over and over
            again. Basically, a moment of zen to save our surf brains. Because
            let’s face it, we all love amazing waves. That’s why we all do what
            we do.
          </h3>
        </div>
      </div>
      <div className="relative">
        <img
          src={rollingWave}
          alt="Rolling Wave"
          className="block w-full"
          width="2614"
          height="1530"
        />
        <div className="absolute bottom-0 left-0 right-0 z-10 h-40 bg-gradient-to-b from-transparent to-cello" />
      </div>
      <div className="bg-cello py-8 px-5 lg:py-16">
        <div className="mx-auto max-w-6xl text-center text-[1rem] leading-normal text-white md:text-[1.5rem] lg:text-[2rem] lg:leading-tight xl:text-[3rem]">
          <h3>
            And now you are here, you might even want to check out this epic app
            we put together with pipe legend Jamie O’Brien.
          </h3>
          <section ref={refSectionPostcards}>
            <div className="sticky top-0 flex min-h-screen flex-col pt-6 md:pt-8 lg:pt-10 xl:pt-12">
              <div className="flex grow flex-col items-center space-y-6 md:space-y-8 lg:space-y-12">
                <div className="flex shrink-0 flex-col items-center space-y-6 lg:space-y-8 xl:space-y-12">
                  <a
                    href={
                      isTouchDevice()
                        ? 'https://surfapp.app.link/sosb'
                        : 'https://jamieobrien.com/surf-app'
                    }
                    className="inline-block bg-pizazz px-6 py-2 font-headings text-[1rem] uppercase leading-[1.4] duration-200 md:text-[1.5rem] lg:px-20 lg:py-5 lg:text-[1.75rem] lg:leading-[1.16] xl:text-[2.375rem] hover-hover:hover:bg-orange-600"
                  >
                    Download surf app
                  </a>
                  <h3>But we get it if you don’t and that’s cool too.</h3>
                  <h3>Enjoy the rest of the comp.</h3>
                </div>
                <div className="flex w-full max-w-[220px] grow xs:max-w-[270px] sm:max-w-[490px] md:max-w-[620px] lg:max-w-[860px]">
                  <div className="relative w-full">
                    <div className="absolute top-0 bottom-0 m-auto flex h-4/6 w-full rotate-[-17deg] items-center justify-center">
                      <div className="aspect-[1920/1080] h-auto max-h-full w-auto max-w-full border border-gray-300 bg-white p-2 drop-shadow-lg md:p-3 lg:p-4">
                        <video autoPlay loop muted playsInline>
                          <source src={cardVideo01} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                    <motion.div
                      className="absolute top-0 bottom-0 m-auto flex h-4/6 w-full items-center justify-center will-change-transform"
                      initial={{
                        rotate: '-6deg',
                      }}
                      animate={{
                        rotate: '-6deg',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      style={{
                        y: scrollCard02,
                      }}
                    >
                      <div className="aspect-[1920/1080] h-auto max-h-full w-auto max-w-full border border-gray-300 bg-white p-2 drop-shadow-lg md:p-3 lg:p-4">
                        <video autoPlay loop muted playsInline>
                          <source src={cardVideo02} type="video/mp4" />
                        </video>
                      </div>
                    </motion.div>
                    <motion.div
                      className="absolute top-0 bottom-0 m-auto flex h-4/6 w-full items-center justify-center will-change-transform"
                      initial={{
                        rotate: '12deg',
                      }}
                      animate={{
                        rotate: '12deg',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      style={{
                        y: scrollCard01,
                      }}
                    >
                      <div className="aspect-[1920/1080] h-auto max-h-full w-auto max-w-full border border-gray-300 bg-white p-2 drop-shadow-lg md:p-3 lg:p-4">
                        <video autoPlay loop muted playsInline>
                          <source src={cardVideo03} type="video/mp4" />
                        </video>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[150vh]" />
          </section>
        </div>
      </div>

      <div className="px-14 py-12 lg:py-20">
        <div className="mx-auto max-w-5xl text-center text-[0.75rem] leading-[1.9] md:text-[1rem] lg:text-[1.625rem] lg:leading-relaxed">
          PS, we believe in giving back to the ocean because it gives us so
          much. Therefore, if this site gets a million hits before the end of
          the WSL finals, we will plant 2,500 Sea Trees.
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
