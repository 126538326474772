const Footer = () => (
  <footer className="border-t border-cello pt-11 pb-6 lg:py-20">
    <div className="container flex flex-col-reverse items-center justify-between text-[0.75rem] md:flex-row md:text-[0.9rem] lg:text-[19px]">
      <span className="mt-8 md:mt-0">
        2022 Save Our Surf Brains. All Rights Reserved
      </span>
      <a
        href="https://intercom.help/twyn/en/articles/5178495-privacy-notice"
        className="hover-hover:hover:underline"
      >
        Privacy Policy
      </a>
    </div>
  </footer>
)

export default Footer
